import type { SliderCollectionResponse } from "@/data/__generated__/types/generated";

import Link from "next/link";

type SliderMobileTextProps = {
	activeSlide?: SliderCollectionResponse;
};

const SliderMobileText: React.FC<SliderMobileTextProps> = ({ activeSlide }) => {
	if (!activeSlide) return null;
	return (
		<div className="bg-white rounded-3xl p-4 sm:flex flex-col hidden">
			<p id="slide_description__top_title" className="mb-2">
				{`${activeSlide?.manufacturerTitle}, ${activeSlide?.countryTitle}`}
			</p>
			<p id="slide_description__title" className="font-medium text-lg">
				{activeSlide?.title?.replace(activeSlide?.manufacturerTitle || "", "")}
			</p>
			<Link className="mt-4" href={`/catalog/${activeSlide?.slug}`}>
				<button
					className="px-6 py-3 bg-accentGreen hover:bg-greenHover rounded-xl text-white font-bold"
					type="button"
					aria-label="Посмотреть"
				>
					Посмотреть
				</button>
			</Link>
		</div>
	);
};

export default SliderMobileText;
