import InfoGridItems from "@/presentation/components/InfoGridItems";

const InfoSection: React.FC = () => {
	return (
		<div className="mt-[120px] sm:mt-12">
			<div className="home-green-clip bg-accentGreen flex items-center px-10">
				<h2 className="font-medium text-white text-5xl max-w-[900px] leading-[60px] relative bottom-1 sm:text-3xl sm:bottom-5">
					Испанская и Итальянская керамическая плитка в Москве
				</h2>
			</div>
			<div className="home-white-clip bg-white relative bottom-6 flex items-center px-10">
				<div className="max-w-[708px] text-darkGray ml-auto flex flex-col pb-10 h-full">
					<p className="mt-auto pt-20 sm:text-sm">
						При всем многообразии керамики, множества видов, цветов и оттенков, когда глаза разбегаются от вариантов,
						достаточно сложно выбрать действительно качественную керамическую плитку по приемлемой цене – в хорошем
						соотношении цена – качество.Наша цель - помочь сделать вам правильный выбор! Магазин Xplitka.ru предлагает в
						каталоге товаров продукцию надежных, всемирно известных производителей. В сервис магазина плитки, входит
						своевременная доставка по городу Москве и Московской области.
					</p>
					<p className="mt-4">Приятных Вам покупок Уважаемые покупатели!</p>
				</div>
			</div>
			<InfoGridItems />
		</div>
	);
};

export default InfoSection;
