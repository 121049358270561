import { INFO_GRID_ITEMS } from "@/constants";
import { cn } from "@/libs/utils";

type InfoGridItemsProps = {
	className?: string;
};

const InfoGridItems: React.FC<InfoGridItemsProps> = ({ className }) => {
	return (
		<div
			className={cn("border-2 border-white rounded-3xl grid grid-cols-3 p-10 gap-10 sm:flex sm:flex-col", className)}
		>
			{INFO_GRID_ITEMS.map((item) => {
				return (
					<div key={item.title}>
						<h3 className="text-accentGreen font-medium mb-4 sm:mb-2">{item.title}</h3>
						<p className="text-darkGray">{item.text}</p>
					</div>
				);
			})}
		</div>
	);
};

export default InfoGridItems;
