import type { SliderCollectionResponse } from "@/data/__generated__/types/generated";

import Link from "next/link";

type SliderTextProps = {
	activeSlide?: SliderCollectionResponse;
};

const SliderText: React.FC<SliderTextProps> = ({ activeSlide }) => {
	return (
		<div className="absolute w-[473px] h-[184px] rounded-xl bg-white/50 backdrop-blur-lg z-20 left-3 bottom-3 p-6 sm:hidden">
			<div className="slide_description mb-6">
				<p id="slide_description__top_title" className="text-lg mb-2">
					{`${activeSlide?.manufacturerTitle}, ${activeSlide?.countryTitle}`}
				</p>
				<p id="slide_description__title" className="font-medium text-2xl">
					{activeSlide?.title?.replace(activeSlide?.manufacturerTitle || "", "")}
				</p>
			</div>
			<div className="mb-auto flex justify-between items-end">
				<Link href={`/catalog/${activeSlide?.slug}`}>
					<button
						className="px-6 py-3 bg-accentGreen hover:bg-greenHover rounded-xl text-white font-bold"
						type="button"
						aria-label="Посмотреть"
					>
						Посмотреть
					</button>
				</Link>
				<div className="swiper-pagination" />
			</div>
		</div>
	);
};

export default SliderText;
