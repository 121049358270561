export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type ActiveCategoryInput = {
  url: Scalars['String']['input'];
};

export type ActiveCategoryResponse = {
  __typename?: 'ActiveCategoryResponse';
  /** The description text displayed for this category on the frontend */
  description?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if the category has subcategories */
  haveChildrens: Scalars['Boolean']['output'];
  /** The header text displayed for this category on the frontend */
  header?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the category */
  id: Scalars['Int']['output'];
  /** The left-most position of the category in the tree */
  lft: Scalars['Int']['output'];
  /** The meta description of the category, used by search engines */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** The meta title of the category, used by search engines */
  metaTitle?: Maybe<Scalars['String']['output']>;
  /** The right-most position of the category in the tree */
  rgt: Scalars['Int']['output'];
  /** The URL-friendly slug of the category */
  slug: Scalars['String']['output'];
  /** The title of the category */
  title: Scalars['String']['output'];
};

export type ArticleInput = {
  url: Scalars['String']['input'];
};

export type ArticleResponse = {
  __typename?: 'ArticleResponse';
  /** The content of the article, if available */
  content?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the article */
  id: Scalars['Int']['output'];
  /** The URL of the image associated with the article, if available */
  image?: Maybe<Scalars['String']['output']>;
  /** The meta description of the article, if provided */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** The meta title of the article, if specified */
  metaTitle?: Maybe<Scalars['String']['output']>;
  /** The URL-friendly slug for the article */
  slug: Scalars['String']['output'];
  /** The title of the article */
  title: Scalars['String']['output'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
};

export type CategoryFiltersInput = {
  /** The left-most position of the category in the tree */
  categoryLft: Scalars['Int']['input'];
  /** The right-most position of the category in the tree */
  categoryRgt: Scalars['Int']['input'];
  /** The URL of the category to return */
  categoryUrl: Scalars['String']['input'];
};

export type CategoryItemsResponse = {
  __typename?: 'CategoryItemsResponse';
  /** Next page */
  nextPage?: Maybe<Scalars['Int']['output']>;
  /** Items without collection */
  products: Array<ItemWithoutCollection>;
};

export type CategoryResponse = {
  __typename?: 'CategoryResponse';
  /** The list of subcategories belonging to this category */
  childCategories: Array<ChildCategory>;
  /** The unique identifier of the category */
  id: Scalars['Int']['output'];
  /** The URL-friendly slug of the category */
  slug: Scalars['String']['output'];
  /** The title of the category */
  title: Scalars['String']['output'];
};

export type ChildCategory = {
  __typename?: 'ChildCategory';
  /** The unique identifier of the subcategory */
  id: Scalars['Int']['output'];
  /** The URL-friendly slug of the subcategory */
  slug: Scalars['String']['output'];
  /** The title of the subcategory */
  title: Scalars['String']['output'];
};

export type CollectionImageType = {
  __typename?: 'CollectionImageType';
  /** The filename of the image */
  filename?: Maybe<Scalars['String']['output']>;
};

export type CollectionInput = {
  url: Scalars['String']['input'];
};

export type CollectionLabels = {
  __typename?: 'CollectionLabels';
  /** The title of the label */
  title: Scalars['String']['output'];
};

export type CollectionOptionsResponse = {
  __typename?: 'CollectionOptionsResponse';
  /** The minimum price of the collection's items. Can be null if the collection does not have any items, or if the minimum price of its items is unknown. */
  minPrice?: Maybe<Scalars['String']['output']>;
  /** A list of URLs to size images of the collection. Sizes are typically used in a product detail view. They are smaller versions of the main image, but not as small as thumbnails. Can be null if the collection does not have any size images. */
  sizes?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of URLs to thumbnail images of the collection. Each thumbnail is a smaller version of the main image. They are typically used in carousel or slider components. Can be null if the collection does not have any thumbnails. */
  thumbnails?: Maybe<Array<Scalars['String']['output']>>;
};

export type CollectionResponse = {
  __typename?: 'CollectionResponse';
  /** The slug of the country of the collection */
  countrySlug: Scalars['String']['output'];
  /** The title of the country of the collection */
  countryTitle: Scalars['String']['output'];
  /** The unique identifier of the collection */
  id: Scalars['Int']['output'];
  /** The main image of the collection */
  image?: Maybe<Scalars['String']['output']>;
  /** The labels of the collection, such as 'New', 'Sale', 'Popular' */
  labels: Array<CollectionLabels>;
  /** The slug of the manufacturer of the collection */
  manufacturerSlug: Scalars['String']['output'];
  /** The title of the manufacturer of the collection */
  manufacturerTitle: Scalars['String']['output'];
  /** Retrieve the additional options of the collection, such as thumbnail images and minimum price of its items */
  options: CollectionOptionsResponse;
  /** The slug of the collection, used in the URL */
  slug: Scalars['String']['output'];
  /** The title of the collection */
  title: Scalars['String']['output'];
};

export type CollectionSuggestion = {
  __typename?: 'CollectionSuggestion';
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CollectionsByLabelInput = {
  label: LabelInput;
  limit?: Scalars['Int']['input'];
};

export type CollectionsByManufacturerInput = {
  /** Filters */
  filters?: InputMaybe<FilterInput>;
  limit?: Scalars['Int']['input'];
  manufacturerSlug: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyInStock?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountryResponse = {
  __typename?: 'CountryResponse';
  /** An optional description of the country */
  description?: Maybe<Scalars['String']['output']>;
  /** An optional header for the country */
  header?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the country */
  id: Scalars['Int']['output'];
  /** An optional meta description for the country */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** An optional meta title for the country */
  metaTitle?: Maybe<Scalars['String']['output']>;
  /** A URL-friendly representation of the country name */
  slug: Scalars['String']['output'];
  /** The name of the country */
  title: Scalars['String']['output'];
};

export type CreateDiscountRequestResponse = {
  __typename?: 'CreateDiscountRequestResponse';
  /** The unique identifier of the discount request */
  requestId?: Maybe<Scalars['Int']['output']>;
};

export type CreateOrderInput = {
  /** Comment of the order */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Email of the customer */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Items of the order */
  items: Array<OrderItemInput>;
  /** Name of the customer */
  name: Scalars['String']['input'];
  /** Phone of the customer */
  phone: Scalars['String']['input'];
  /** Store of the order */
  store?: InputMaybe<Scalars['String']['input']>;
  /** Total amount of the order */
  total: Scalars['Int']['input'];
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  /** The unique identifier of the discount request */
  hash?: Maybe<Scalars['String']['output']>;
};

export type DiscountRequestInput = {
  /** The amount of packs to be discounted */
  amount: Scalars['Int']['input'];
  /** The id of the item to be discounted */
  itemId: Scalars['Int']['input'];
  /** The phone number of the customer */
  phone: Scalars['String']['input'];
};

export type FilterInput = {
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  color?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  purpose?: InputMaybe<Array<Scalars['String']['input']>>;
  roomType?: InputMaybe<Array<Scalars['String']['input']>>;
  shape?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Array<Scalars['String']['input']>>;
  surface?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FiltersByManufacturerInput = {
  manufacturerUrl: Scalars['String']['input'];
};

export type ItemByIdInput = {
  /** Item id */
  id: Scalars['Int']['input'];
};

export type ItemCollectionsSuggestionsResponse = {
  __typename?: 'ItemCollectionsSuggestionsResponse';
  /** Suggestions */
  suggestions: SuggestionsResponse;
};

export type ItemImageResponse = {
  __typename?: 'ItemImageResponse';
  filename: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type ItemListType = {
  __typename?: 'ItemListType';
  /** Item list */
  items: Array<ListItemResponse>;
  /** Next page */
  nextPage?: Maybe<Scalars['Int']['output']>;
};

export type ItemOrderResponse = {
  __typename?: 'ItemOrderResponse';
  /** Min order price */
  minOrderPrice?: Maybe<Scalars['Float']['output']>;
  /** Order amounts */
  orderAmounts: Array<OrderAmountResponse>;
};

export type ItemPropertyResponse = {
  __typename?: 'ItemPropertyResponse';
  /** Type property */
  typeProperty: Scalars['String']['output'];
  /** Type property id */
  typePropertyId?: Maybe<Scalars['Int']['output']>;
  /** Type property slug */
  typePropertySlug?: Maybe<Scalars['String']['output']>;
  /** Type property value */
  value?: Maybe<Scalars['String']['output']>;
  /** Type property value slug */
  valueSlug?: Maybe<Scalars['String']['output']>;
};

export type ItemResponse = {
  __typename?: 'ItemResponse';
  categorySlug?: Maybe<Scalars['String']['output']>;
  categoryTitle?: Maybe<Scalars['String']['output']>;
  collectionSlug?: Maybe<Scalars['String']['output']>;
  collectionTitle?: Maybe<Scalars['String']['output']>;
  countrySlug?: Maybe<Scalars['String']['output']>;
  countryTitle?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  itemImages?: Maybe<Array<ItemImageResponse>>;
  manufacturerSlug?: Maybe<Scalars['String']['output']>;
  manufacturerTitle?: Maybe<Scalars['String']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  order: ItemOrderResponse;
  pdf?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  properties: Array<ItemPropertyResponse>;
  providerTitle?: Maybe<Scalars['String']['output']>;
  sellByUnit?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  unitTitle?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
};

export type ItemSearchSuggestion = {
  __typename?: 'ItemSearchSuggestion';
  collectionSlug?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ItemSuggestion = {
  __typename?: 'ItemSuggestion';
  collectionSlug?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ItemSuggestionsInput = {
  /** Keyword */
  keyword: Scalars['String']['input'];
};

export type ItemSuggestionsResponse = {
  __typename?: 'ItemSuggestionsResponse';
  /** Item suggestions */
  suggestions: Array<ItemSuggestion>;
};

export type ItemWithoutCollection = {
  __typename?: 'ItemWithoutCollection';
  /** Item category slug */
  categorySlug?: Maybe<Scalars['String']['output']>;
  /** Item category title */
  categoryTitle?: Maybe<Scalars['String']['output']>;
  /** Item country slug */
  countrySlug?: Maybe<Scalars['String']['output']>;
  /** Item country title */
  countryTitle?: Maybe<Scalars['String']['output']>;
  /** Item header */
  header?: Maybe<Scalars['String']['output']>;
  /** Item id */
  id: Scalars['Int']['output'];
  /** Item image url */
  image?: Maybe<Scalars['String']['output']>;
  /** Item manufacturer slug */
  manufacturerSlug?: Maybe<Scalars['String']['output']>;
  /** Item manufacturer title */
  manufacturerTitle?: Maybe<Scalars['String']['output']>;
  /** Item price */
  price?: Maybe<Scalars['Int']['output']>;
  /** Item status */
  status: Scalars['String']['output'];
  /** Item title */
  title: Scalars['String']['output'];
  /** Item unit title */
  unitTitle?: Maybe<Scalars['String']['output']>;
};

export type ItemsByCategoryInput = {
  /** Category lft */
  categoryLft: Scalars['Int']['input'];
  /** Category rgt */
  categoryRgt: Scalars['Int']['input'];
  /** Limit */
  limit?: Scalars['Int']['input'];
  /** Offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemsByCollectionInput = {
  /** Collection url */
  collectionUrl: Scalars['String']['input'];
  /** Limit */
  limit?: Scalars['Int']['input'];
  /** Offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemsByManufacturerInput = {
  filters?: InputMaybe<FilterInput>;
  limit?: Scalars['Int']['input'];
  manufacturerSlug: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyInStock?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ItemsInput = {
  /** Category lft */
  categoryLft: Scalars['Int']['input'];
  /** Category rgt */
  categoryRgt: Scalars['Int']['input'];
  /** Filters */
  filters?: InputMaybe<FilterInput>;
  /** Keyword */
  keyword?: InputMaybe<Scalars['String']['input']>;
  /** Limit */
  limit?: Scalars['Int']['input'];
  /** Offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The type of label used to categorize products */
export enum LabelInput {
  /** Label indicating the product is archived */
  Archive = 'ARCHIVE',
  /** Label indicating the product has high sales */
  Hit = 'HIT',
  /** Label indicating the product is a sample from the north */
  North = 'NORTH',
  /** Label indicating the product is popular */
  Popular = 'POPULAR',
  /** Label indicating the product is on sale */
  Sale = 'SALE',
  /** Label indicating the product is a sample from the west */
  West = 'WEST'
}

/** Represents a link with its title, image, and url */
export type LinkResponse = {
  __typename?: 'LinkResponse';
  /** Unique identifier of the link */
  id: Scalars['Int']['output'];
  /** URL of the image associated with the link, if any */
  image: Scalars['String']['output'];
  /** Title of the link, used for display purposes */
  title: Scalars['String']['output'];
  /** URL of the link, where the user will be redirected */
  url: Scalars['String']['output'];
};

export type ListCollectionsInput = {
  categoryLft: Scalars['Int']['input'];
  categoryRgt: Scalars['Int']['input'];
  /** Filters */
  filters?: InputMaybe<FilterInput>;
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListCollectionsResponse = {
  __typename?: 'ListCollectionsResponse';
  /** An array of collection objects representing the collections of the collection */
  collections: Array<CollectionResponse>;
  /** The next page number to use in pagination, if applicable. Null if there are no more pages. */
  nextPage?: Maybe<Scalars['Int']['output']>;
};

export type ListItemResponse = {
  __typename?: 'ListItemResponse';
  /** Item category slug */
  categorySlug?: Maybe<Scalars['String']['output']>;
  /** Item category title */
  categoryTitle?: Maybe<Scalars['String']['output']>;
  /** Item collection slug */
  collectionSlug?: Maybe<Scalars['String']['output']>;
  /** Item collection title */
  collectionTitle?: Maybe<Scalars['String']['output']>;
  /** Item country slug */
  countrySlug?: Maybe<Scalars['String']['output']>;
  /** Item country title */
  countryTitle?: Maybe<Scalars['String']['output']>;
  /** Item description */
  desscription?: Maybe<Scalars['String']['output']>;
  /** Item header */
  header?: Maybe<Scalars['String']['output']>;
  /** Item id */
  id: Scalars['Int']['output'];
  /** Item image url */
  image?: Maybe<Scalars['String']['output']>;
  /** Item manufacturer slug */
  manufacturerSlug?: Maybe<Scalars['String']['output']>;
  /** Item manufacturer title */
  manufacturerTitle?: Maybe<Scalars['String']['output']>;
  /** Item order */
  order: ItemOrderResponse;
  /** Item price */
  price?: Maybe<Scalars['Int']['output']>;
  /** Item properties */
  properties: Array<ItemPropertyResponse>;
  /** Item sell by unit */
  sellByUnit?: Maybe<Scalars['Int']['output']>;
  /** Item status */
  status: Scalars['String']['output'];
  /** Item title */
  title: Scalars['String']['output'];
  /** Item unit title */
  unitTitle?: Maybe<Scalars['String']['output']>;
};

export type ListManufacturersResponse = {
  __typename?: 'ListManufacturersResponse';
  /** The list of manufacturers */
  manufacturerList: Array<ManufaturerExtendedResponse>;
  /** The next page number */
  nextPage?: Maybe<Scalars['Int']['output']>;
  /** The total number of manufacturers */
  totalCount: Scalars['Int']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ManufacturerGroupResponse = {
  __typename?: 'ManufacturerGroupResponse';
  /** The key of the group */
  key: Scalars['String']['output'];
  /** The list of manufacturers in the group */
  manufacturers: Array<ShortManufacturerResponse>;
};

export type ManufacturerInput = {
  slug: Scalars['String']['input'];
};

export type ManufacturerResponse = {
  __typename?: 'ManufacturerResponse';
  /** The URL-friendly slug of the country of the manufacturer */
  countrySlug: Scalars['String']['output'];
  /** The title of the country of the manufacturer */
  countryTitle: Scalars['String']['output'];
  /** The description of the manufacturer */
  description?: Maybe<Scalars['String']['output']>;
  /** The header of the manufacturer */
  header?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the manufacturer */
  id: Scalars['Int']['output'];
  /** The URL of the logo of the manufacturer */
  logo?: Maybe<Scalars['String']['output']>;
  /** The meta description of the manufacturer */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** The meta title of the manufacturer */
  metaTitle?: Maybe<Scalars['String']['output']>;
  /** The URL-friendly slug of the manufacturer */
  slug: Scalars['String']['output'];
  /** The title of the manufacturer */
  title: Scalars['String']['output'];
  /** The URL of the video of the manufacturer */
  video?: Maybe<Scalars['String']['output']>;
};

export type ManufacturersInput = {
  /** The country of the manufacturer to return */
  country?: InputMaybe<Scalars['String']['input']>;
  /** The first letter of the manufacturer to return */
  firstLetter?: InputMaybe<Scalars['String']['input']>;
  /** The number of manufacturers to return */
  limit?: Scalars['Int']['input'];
  /** The offset of the manufacturers to return */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ManufaturerExtendedResponse = {
  __typename?: 'ManufaturerExtendedResponse';
  /** The title of the country of the manufacturer */
  countryTitle?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the manufacturer */
  id: Scalars['Int']['output'];
  /** The URL of the logo of the manufacturer */
  logo?: Maybe<Scalars['String']['output']>;
  /** The minimum price of the products of the manufacturer */
  priceFrom?: Maybe<Scalars['Int']['output']>;
  /** The URL-friendly slug of the manufacturer */
  slug: Scalars['String']['output'];
  /** The title of the manufacturer */
  title: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createDiscountRequest: CreateDiscountRequestResponse;
  /** Create an order */
  createOrder: CreateOrderResponse;
  dummy: Scalars['Boolean']['output'];
  login?: Maybe<AuthResponse>;
};


export type MutationCreateDiscountRequestArgs = {
  input: DiscountRequestInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};

export type NewCollectionsInput = {
  limit?: Scalars['Int']['input'];
};

export type OrderAmountResponse = {
  __typename?: 'OrderAmountResponse';
  /** Order amount key */
  key: Scalars['String']['output'];
  /** Order amount title */
  title: Scalars['String']['output'];
  /** Order amount value */
  value: Scalars['Float']['output'];
};

export type OrderCustomerResponse = {
  __typename?: 'OrderCustomerResponse';
  /** The email of the customer */
  email?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the customer */
  id: Scalars['Int']['output'];
  /** The name of the customer */
  name?: Maybe<Scalars['String']['output']>;
  /** The phone of the customer */
  phone?: Maybe<Scalars['String']['output']>;
};

export type OrderInput = {
  /** The unique identifier of the order */
  hash: Scalars['String']['input'];
  /** The pin of the order */
  pin: Scalars['String']['input'];
};

export type OrderItemInput = {
  /** The amount of the item */
  amount: Scalars['Int']['input'];
  /** The unique identifier of the item */
  id: Scalars['Int']['input'];
  /** The price of the item */
  price: Scalars['Int']['input'];
};

export type OrderListItemResponse = {
  __typename?: 'OrderListItemResponse';
  /** The amount of the item */
  amount: Scalars['Int']['output'];
  /** The slug of the category */
  categorySlug?: Maybe<Scalars['String']['output']>;
  /** The slug of the collection */
  collectionSlug?: Maybe<Scalars['String']['output']>;
  /** The header of the item */
  itemHeader?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the item */
  itemId: Scalars['Int']['output'];
  /** The image of the item */
  itemImage?: Maybe<Scalars['String']['output']>;
  /** The price of the item */
  itemPrice?: Maybe<Scalars['Int']['output']>;
  /** The title of the item */
  itemTitle: Scalars['String']['output'];
  /** The price of the item */
  price: Scalars['Int']['output'];
  /** The title of the unit */
  unitTitle: Scalars['String']['output'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  /** The comment of the order */
  comment?: Maybe<Scalars['String']['output']>;
  /** The date of the order creation */
  created: Scalars['Date']['output'];
  /** The customer of the order */
  customer?: Maybe<OrderCustomerResponse>;
  /** The delivery of the order */
  delivery?: Maybe<Scalars['Int']['output']>;
  /** The discount of the order */
  discount?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier of the order */
  hash?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the order */
  id: Scalars['Int']['output'];
  /** The items of the order */
  orderItems?: Maybe<Array<OrderListItemResponse>>;
  /** The pin of the order */
  pin?: Maybe<Scalars['String']['output']>;
  /** The status of the order */
  status: Scalars['String']['output'];
  /** The total amount of the order */
  total: Scalars['Int']['output'];
  /** The date of the order update */
  updated?: Maybe<Scalars['Date']['output']>;
};

export type PageInput = {
  /** The page slug */
  slug: Scalars['String']['input'];
};

export type PageResponse = {
  __typename?: 'PageResponse';
  /** The page content */
  content: Scalars['String']['output'];
  /** The page header */
  header?: Maybe<Scalars['String']['output']>;
  /** The page id */
  id: Scalars['Int']['output'];
  /** The page meta description */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** The page meta title */
  metaTitle?: Maybe<Scalars['String']['output']>;
  /** The page slug */
  slug: Scalars['String']['output'];
  /** The page title */
  title: Scalars['String']['output'];
};

export type PageSeoInput = {
  url: Scalars['String']['input'];
};

export type PageSeoResponse = {
  __typename?: 'PageSeoResponse';
  /** The meta description of the webpage, a concise summary of the webpage, often displayed in search engine results. */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** The meta title of the webpage, typically displayed in the browser tab. */
  metaTitle?: Maybe<Scalars['String']['output']>;
};

export type PaymentInput = {
  /** Total amount */
  amount: Scalars['Int']['input'];
  /** Order hash */
  hash: Scalars['String']['input'];
  /** Order id */
  orderId: Scalars['Int']['input'];
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  url: Scalars['String']['output'];
};

export type PropertyGroupResponse = {
  __typename?: 'PropertyGroupResponse';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  values: Array<PropertyResponse>;
};

export type PropertyResponse = {
  __typename?: 'PropertyResponse';
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  activeCategory?: Maybe<ActiveCategoryResponse>;
  article?: Maybe<ArticleResponse>;
  articles: Array<ArticleResponse>;
  categories: Array<CategoryResponse>;
  categoryFilters: Array<PropertyGroupResponse>;
  categoryItems: ItemListType;
  collection?: Maybe<SingleCollectionResponse>;
  collections: ListCollectionsResponse;
  collectionsByLabel: Array<CollectionResponse>;
  collectionsByManufacturer: ListCollectionsResponse;
  /** A list of all available countries in the database */
  countries: Array<CountryResponse>;
  filtersByManufacturer: Array<PropertyGroupResponse>;
  item?: Maybe<ItemResponse>;
  items: ItemListType;
  itemsByCollection: ItemListType;
  itemsByManufacturer: ItemListType;
  itemsSuggestions: ItemSuggestionsResponse;
  /** Fetch a list of links. Returns an array of LinkResponse objects. */
  links: Array<LinkResponse>;
  manufacturer?: Maybe<ManufacturerResponse>;
  manufacturers: ListManufacturersResponse;
  manufacturersGroups: Array<ManufacturerGroupResponse>;
  newCollections: Array<CollectionResponse>;
  /** Get an order by hash */
  order?: Maybe<OrderResponse>;
  page?: Maybe<PageResponse>;
  pageSeo: PageSeoResponse;
  /** Create a payment url */
  paymentUrl: PaymentResponse;
  redirects: Array<RedirectResponse>;
  sanvizOptions: Array<WebGlOption>;
  siteMessage?: Maybe<Scalars['String']['output']>;
  sliderCollections: Array<SliderCollectionResponse>;
  suggestions: SuggestionsResponse;
};


export type QueryActiveCategoryArgs = {
  input: ActiveCategoryInput;
};


export type QueryArticleArgs = {
  input: ArticleInput;
};


export type QueryCategoryFiltersArgs = {
  input: CategoryFiltersInput;
};


export type QueryCategoryItemsArgs = {
  input: ItemsByCategoryInput;
};


export type QueryCollectionArgs = {
  input: CollectionInput;
};


export type QueryCollectionsArgs = {
  input: ListCollectionsInput;
};


export type QueryCollectionsByLabelArgs = {
  input: CollectionsByLabelInput;
};


export type QueryCollectionsByManufacturerArgs = {
  input: CollectionsByManufacturerInput;
};


export type QueryFiltersByManufacturerArgs = {
  input: FiltersByManufacturerInput;
};


export type QueryItemArgs = {
  input: ItemByIdInput;
};


export type QueryItemsArgs = {
  input: ItemsInput;
};


export type QueryItemsByCollectionArgs = {
  input: ItemsByCollectionInput;
};


export type QueryItemsByManufacturerArgs = {
  input: ItemsByManufacturerInput;
};


export type QueryItemsSuggestionsArgs = {
  input: ItemSuggestionsInput;
};


export type QueryManufacturerArgs = {
  input: ManufacturerInput;
};


export type QueryManufacturersArgs = {
  input: ManufacturersInput;
};


export type QueryNewCollectionsArgs = {
  input: NewCollectionsInput;
};


export type QueryOrderArgs = {
  input: OrderInput;
};


export type QueryPageArgs = {
  input: PageInput;
};


export type QueryPageSeoArgs = {
  input: PageSeoInput;
};


export type QueryPaymentUrlArgs = {
  input: PaymentInput;
};


export type QuerySliderCollectionsArgs = {
  input: SliderCollectionsInput;
};


export type QuerySuggestionsArgs = {
  input: SuggestionsInput;
};

export type RedirectResponse = {
  __typename?: 'RedirectResponse';
  /** The URL to redirect from */
  fromUrl: Scalars['String']['output'];
  /** The unique identifier of the redirect */
  id: Scalars['Int']['output'];
  /** The URL to redirect to */
  toUrl: Scalars['String']['output'];
};

export type ShortManufacturerResponse = {
  __typename?: 'ShortManufacturerResponse';
  /** The URL-friendly slug of the manufacturer */
  slug: Scalars['String']['output'];
  /** The title of the manufacturer */
  title: Scalars['String']['output'];
};

export type SingleCollectionResponse = {
  __typename?: 'SingleCollectionResponse';
  collectionImages?: Maybe<Array<CollectionImageType>>;
  /** The slug of the country of the collection */
  countrySlug: Scalars['String']['output'];
  /** The title of the country of the collection */
  countryTitle: Scalars['String']['output'];
  /** The description of the collection */
  description?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the collection */
  id: Scalars['Int']['output'];
  /** The main image of the collection */
  image?: Maybe<Scalars['String']['output']>;
  /** The labels of the collection, such as 'New', 'Sale', 'Popular' */
  labels: Array<CollectionLabels>;
  /** The slug of the manufacturer of the collection */
  manufacturerSlug: Scalars['String']['output'];
  /** The title of the manufacturer of the collection */
  manufacturerTitle: Scalars['String']['output'];
  /** The meta description of the collection */
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** The meta title of the collection */
  metaTitle?: Maybe<Scalars['String']['output']>;
  /** Retrieve the additional options of the collection, such as thumbnail images and minimum price of its items */
  options: CollectionOptionsResponse;
  /** The URL of the PDF of the collection */
  pdf?: Maybe<Scalars['String']['output']>;
  /** The slug of the collection, used in the URL */
  slug: Scalars['String']['output'];
  /** The title of the collection */
  title: Scalars['String']['output'];
  /** The URL of the video of the collection */
  video?: Maybe<Scalars['String']['output']>;
};

export type SliderCollectionResponse = {
  __typename?: 'SliderCollectionResponse';
  /** The title of the country where the collection is located */
  countryTitle: Scalars['String']['output'];
  /** The unique identifier of the collection */
  id: Scalars['Int']['output'];
  /** The main image of the collection */
  image?: Maybe<Scalars['String']['output']>;
  /** The title of the manufacturer of the collection */
  manufacturerTitle: Scalars['String']['output'];
  /** The slug of the collection, used in the URL */
  slug: Scalars['String']['output'];
  /** The title of the collection */
  title: Scalars['String']['output'];
};

export type SliderCollectionsInput = {
  label: LabelInput;
  limit?: Scalars['Int']['input'];
};

export type SuggestionsInput = {
  /** The keyword of the suggestion */
  keyword: Scalars['String']['input'];
};

export type SuggestionsResponse = {
  __typename?: 'SuggestionsResponse';
  /** Collections suggestions */
  collections: Array<CollectionSuggestion>;
  /** Items suggestions */
  items: Array<ItemSearchSuggestion>;
};

export type WebGlOption = {
  __typename?: 'WebGLOption';
  /** The group of the option */
  group?: Maybe<Scalars['String']['output']>;
  /** The name of the option */
  name: Scalars['String']['output'];
  /** The slug of the option */
  slug?: Maybe<Scalars['String']['output']>;
  /** The value of the option */
  value?: Maybe<Scalars['String']['output']>;
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'CreateOrderResponse', hash?: string | null } };

export type CreateDiscountRequestMutationVariables = Exact<{
  input: DiscountRequestInput;
}>;


export type CreateDiscountRequestMutation = { __typename?: 'Mutation', createDiscountRequest: { __typename?: 'CreateDiscountRequestResponse', requestId?: number | null } };

export type MutationMutationVariables = Exact<{ [key: string]: never; }>;


export type MutationMutation = { __typename?: 'Mutation', dummy: boolean };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'AuthResponse', accessToken?: string | null } | null };

export type ArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type ArticlesQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'ArticleResponse', content?: string | null, id: number, image?: string | null, metaDescription?: string | null, metaTitle?: string | null, slug: string, title: string }> };

export type ArticleQueryVariables = Exact<{
  input: ArticleInput;
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'ArticleResponse', content?: string | null, id: number, image?: string | null, metaDescription?: string | null, metaTitle?: string | null, slug: string, title: string } | null };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'CategoryResponse', id: number, slug: string, title: string, childCategories: Array<{ __typename?: 'ChildCategory', id: number, slug: string, title: string }> }> };

export type ActiveCategoryQueryVariables = Exact<{
  input: ActiveCategoryInput;
}>;


export type ActiveCategoryQuery = { __typename?: 'Query', activeCategory?: { __typename?: 'ActiveCategoryResponse', description?: string | null, haveChildrens: boolean, header?: string | null, id: number, metaDescription?: string | null, metaTitle?: string | null, title: string, slug: string, lft: number, rgt: number } | null };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'CountryResponse', description?: string | null, header?: string | null, id: number, metaDescription?: string | null, metaTitle?: string | null, slug: string, title: string }> };

export type CollectionsQueryVariables = Exact<{
  input: ListCollectionsInput;
}>;


export type CollectionsQuery = { __typename?: 'Query', collections: { __typename?: 'ListCollectionsResponse', nextPage?: number | null, collections: Array<{ __typename?: 'CollectionResponse', countrySlug: string, countryTitle: string, id: number, image?: string | null, manufacturerSlug: string, manufacturerTitle: string, slug: string, title: string, labels: Array<{ __typename?: 'CollectionLabels', title: string }>, options: { __typename?: 'CollectionOptionsResponse', minPrice?: string | null, sizes?: Array<string> | null, thumbnails?: Array<string> | null } }> } };

export type CollectionQueryVariables = Exact<{
  input: CollectionInput;
}>;


export type CollectionQuery = { __typename?: 'Query', collection?: { __typename?: 'SingleCollectionResponse', countrySlug: string, countryTitle: string, id: number, image?: string | null, manufacturerSlug: string, manufacturerTitle: string, metaDescription?: string | null, metaTitle?: string | null, pdf?: string | null, slug: string, title: string, video?: string | null, description?: string | null, labels: Array<{ __typename?: 'CollectionLabels', title: string }>, options: { __typename?: 'CollectionOptionsResponse', minPrice?: string | null, sizes?: Array<string> | null, thumbnails?: Array<string> | null }, collectionImages?: Array<{ __typename?: 'CollectionImageType', filename?: string | null }> | null } | null };

export type CollectionsByLabelQueryVariables = Exact<{
  input: CollectionsByLabelInput;
}>;


export type CollectionsByLabelQuery = { __typename?: 'Query', collectionsByLabel: Array<{ __typename?: 'CollectionResponse', countrySlug: string, countryTitle: string, id: number, image?: string | null, manufacturerSlug: string, manufacturerTitle: string, slug: string, title: string, labels: Array<{ __typename?: 'CollectionLabels', title: string }>, options: { __typename?: 'CollectionOptionsResponse', minPrice?: string | null, sizes?: Array<string> | null, thumbnails?: Array<string> | null } }> };

export type CollectionsByManufacturerQueryVariables = Exact<{
  input: CollectionsByManufacturerInput;
}>;


export type CollectionsByManufacturerQuery = { __typename?: 'Query', collectionsByManufacturer: { __typename?: 'ListCollectionsResponse', nextPage?: number | null, collections: Array<{ __typename?: 'CollectionResponse', countrySlug: string, countryTitle: string, id: number, image?: string | null, manufacturerSlug: string, manufacturerTitle: string, slug: string, title: string, labels: Array<{ __typename?: 'CollectionLabels', title: string }>, options: { __typename?: 'CollectionOptionsResponse', minPrice?: string | null, sizes?: Array<string> | null, thumbnails?: Array<string> | null } }> } };

export type NewCollectionsQueryVariables = Exact<{
  input: NewCollectionsInput;
}>;


export type NewCollectionsQuery = { __typename?: 'Query', newCollections: Array<{ __typename?: 'CollectionResponse', countrySlug: string, countryTitle: string, id: number, image?: string | null, manufacturerSlug: string, manufacturerTitle: string, slug: string, title: string, labels: Array<{ __typename?: 'CollectionLabels', title: string }>, options: { __typename?: 'CollectionOptionsResponse', minPrice?: string | null, sizes?: Array<string> | null, thumbnails?: Array<string> | null } }> };

export type SliderCollectionsQueryVariables = Exact<{
  input: SliderCollectionsInput;
}>;


export type SliderCollectionsQuery = { __typename?: 'Query', sliderCollections: Array<{ __typename?: 'SliderCollectionResponse', countryTitle: string, id: number, manufacturerTitle: string, slug: string, title: string, image?: string | null }> };

export type ManufacturersGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ManufacturersGroupsQuery = { __typename?: 'Query', manufacturersGroups: Array<{ __typename?: 'ManufacturerGroupResponse', key: string, manufacturers: Array<{ __typename?: 'ShortManufacturerResponse', slug: string, title: string }> }> };

export type ManufacturerQueryVariables = Exact<{
  input: ManufacturerInput;
}>;


export type ManufacturerQuery = { __typename?: 'Query', manufacturer?: { __typename?: 'ManufacturerResponse', countrySlug: string, countryTitle: string, description?: string | null, header?: string | null, id: number, logo?: string | null, metaDescription?: string | null, metaTitle?: string | null, slug: string, title: string, video?: string | null } | null };

export type ManufacturersQueryVariables = Exact<{
  input: ManufacturersInput;
}>;


export type ManufacturersQuery = { __typename?: 'Query', manufacturers: { __typename?: 'ListManufacturersResponse', nextPage?: number | null, totalCount: number, manufacturerList: Array<{ __typename?: 'ManufaturerExtendedResponse', countryTitle?: string | null, id: number, logo?: string | null, priceFrom?: number | null, slug: string, title: string }> } };

export type LinksQueryVariables = Exact<{ [key: string]: never; }>;


export type LinksQuery = { __typename?: 'Query', links: Array<{ __typename?: 'LinkResponse', id: number, image: string, title: string, url: string }> };

export type CategoryFiltersQueryVariables = Exact<{
  input: CategoryFiltersInput;
}>;


export type CategoryFiltersQuery = { __typename?: 'Query', categoryFilters: Array<{ __typename?: 'PropertyGroupResponse', id: number, title: string, values: Array<{ __typename?: 'PropertyResponse', slug: string, title: string }> }> };

export type FiltersByManufacturerQueryVariables = Exact<{
  input: FiltersByManufacturerInput;
}>;


export type FiltersByManufacturerQuery = { __typename?: 'Query', filtersByManufacturer: Array<{ __typename?: 'PropertyGroupResponse', id: number, title: string, values: Array<{ __typename?: 'PropertyResponse', slug: string, title: string }> }> };

export type SiteMessageQueryVariables = Exact<{ [key: string]: never; }>;


export type SiteMessageQuery = { __typename?: 'Query', siteMessage?: string | null };

export type OrderQueryVariables = Exact<{
  input: OrderInput;
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'OrderResponse', comment?: string | null, hash?: string | null, id: number, status: string, created: any, updated?: any | null, delivery?: number | null, discount?: number | null, pin?: string | null, total: number, customer?: { __typename?: 'OrderCustomerResponse', email?: string | null, id: number, name?: string | null, phone?: string | null } | null, orderItems?: Array<{ __typename?: 'OrderListItemResponse', amount: number, categorySlug?: string | null, collectionSlug?: string | null, itemHeader?: string | null, itemId: number, itemImage?: string | null, itemPrice?: number | null, itemTitle: string, price: number, unitTitle: string }> | null } | null };

export type PageSeoQueryVariables = Exact<{
  input: PageSeoInput;
}>;


export type PageSeoQuery = { __typename?: 'Query', pageSeo: { __typename?: 'PageSeoResponse', metaDescription?: string | null, metaTitle?: string | null } };

export type PaymentQueryVariables = Exact<{
  input: PaymentInput;
}>;


export type PaymentQuery = { __typename?: 'Query', paymentUrl: { __typename?: 'PaymentResponse', url: string } };

export type SuggestionQueryVariables = Exact<{
  input: SuggestionsInput;
}>;


export type SuggestionQuery = { __typename?: 'Query', suggestions: { __typename?: 'SuggestionsResponse', collections: Array<{ __typename?: 'CollectionSuggestion', id: number, image?: string | null, slug: string, thumbnails: Array<string>, title: string }>, items: Array<{ __typename?: 'ItemSearchSuggestion', collectionSlug?: string | null, header?: string | null, id: number, image?: string | null, title: string }> } };

export type PageQueryVariables = Exact<{
  input: PageInput;
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'PageResponse', content: string, header?: string | null, id: number, metaDescription?: string | null, metaTitle?: string | null, slug: string, title: string } | null };

export type ItemsQueryVariables = Exact<{
  input: ItemsInput;
}>;


export type ItemsQuery = { __typename?: 'Query', items: { __typename?: 'ItemListType', nextPage?: number | null, items: Array<{ __typename?: 'ListItemResponse', collectionSlug?: string | null, collectionTitle?: string | null, countrySlug?: string | null, countryTitle?: string | null, header?: string | null, id: number, image?: string | null, manufacturerSlug?: string | null, manufacturerTitle?: string | null, price?: number | null, sellByUnit?: number | null, status: string, title: string, unitTitle?: string | null, order: { __typename?: 'ItemOrderResponse', minOrderPrice?: number | null, orderAmounts: Array<{ __typename?: 'OrderAmountResponse', key: string, title: string, value: number }> }, properties: Array<{ __typename?: 'ItemPropertyResponse', typeProperty: string, typePropertyId?: number | null, typePropertySlug?: string | null, value?: string | null }> }> } };

export type ItemQueryVariables = Exact<{
  input: ItemByIdInput;
}>;


export type ItemQuery = { __typename?: 'Query', item?: { __typename?: 'ItemResponse', collectionSlug?: string | null, collectionTitle?: string | null, countrySlug?: string | null, countryTitle?: string | null, categoryTitle?: string | null, categorySlug?: string | null, header?: string | null, description?: string | null, id: number, image?: string | null, manufacturerSlug?: string | null, manufacturerTitle?: string | null, metaDescription?: string | null, providerTitle?: string | null, metaTitle?: string | null, pdf?: string | null, price?: number | null, sellByUnit?: number | null, status: string, title: string, unitTitle?: string | null, video?: string | null, itemImages?: Array<{ __typename?: 'ItemImageResponse', filename: string, id: number }> | null, order: { __typename?: 'ItemOrderResponse', minOrderPrice?: number | null, orderAmounts: Array<{ __typename?: 'OrderAmountResponse', key: string, title: string, value: number }> }, properties: Array<{ __typename?: 'ItemPropertyResponse', typeProperty: string, typePropertyId?: number | null, typePropertySlug?: string | null, value?: string | null, valueSlug?: string | null }> } | null };

export type CategoryItemsQueryVariables = Exact<{
  input: ItemsByCategoryInput;
}>;


export type CategoryItemsQuery = { __typename?: 'Query', categoryItems: { __typename?: 'ItemListType', nextPage?: number | null, items: Array<{ __typename?: 'ListItemResponse', collectionSlug?: string | null, collectionTitle?: string | null, countrySlug?: string | null, countryTitle?: string | null, categoryTitle?: string | null, categorySlug?: string | null, header?: string | null, id: number, image?: string | null, manufacturerSlug?: string | null, manufacturerTitle?: string | null, price?: number | null, sellByUnit?: number | null, status: string, title: string, unitTitle?: string | null, order: { __typename?: 'ItemOrderResponse', minOrderPrice?: number | null, orderAmounts: Array<{ __typename?: 'OrderAmountResponse', key: string, title: string, value: number }> }, properties: Array<{ __typename?: 'ItemPropertyResponse', typeProperty: string, typePropertyId?: number | null, typePropertySlug?: string | null, value?: string | null }> }> } };

export type ItemsByCollectionQueryVariables = Exact<{
  input: ItemsByCollectionInput;
}>;


export type ItemsByCollectionQuery = { __typename?: 'Query', itemsByCollection: { __typename?: 'ItemListType', nextPage?: number | null, items: Array<{ __typename?: 'ListItemResponse', collectionSlug?: string | null, collectionTitle?: string | null, countrySlug?: string | null, countryTitle?: string | null, header?: string | null, id: number, image?: string | null, manufacturerSlug?: string | null, manufacturerTitle?: string | null, price?: number | null, sellByUnit?: number | null, status: string, title: string, unitTitle?: string | null, order: { __typename?: 'ItemOrderResponse', minOrderPrice?: number | null, orderAmounts: Array<{ __typename?: 'OrderAmountResponse', key: string, title: string, value: number }> }, properties: Array<{ __typename?: 'ItemPropertyResponse', typeProperty: string, typePropertyId?: number | null, typePropertySlug?: string | null, value?: string | null }> }> } };

export type ItemsByManufacturerQueryVariables = Exact<{
  input: ItemsByManufacturerInput;
}>;


export type ItemsByManufacturerQuery = { __typename?: 'Query', itemsByManufacturer: { __typename?: 'ItemListType', nextPage?: number | null, items: Array<{ __typename?: 'ListItemResponse', collectionSlug?: string | null, collectionTitle?: string | null, countrySlug?: string | null, countryTitle?: string | null, categoryTitle?: string | null, categorySlug?: string | null, header?: string | null, id: number, image?: string | null, manufacturerSlug?: string | null, manufacturerTitle?: string | null, price?: number | null, sellByUnit?: number | null, status: string, title: string, unitTitle?: string | null, order: { __typename?: 'ItemOrderResponse', minOrderPrice?: number | null, orderAmounts: Array<{ __typename?: 'OrderAmountResponse', key: string, title: string, value: number }> }, properties: Array<{ __typename?: 'ItemPropertyResponse', typeProperty: string, typePropertyId?: number | null, typePropertySlug?: string | null, value?: string | null }> }> } };

export type ItemQuickSearchQueryVariables = Exact<{
  input: ItemByIdInput;
}>;


export type ItemQuickSearchQuery = { __typename?: 'Query', item?: { __typename?: 'ItemResponse', collectionSlug?: string | null, categorySlug?: string | null, id: number } | null };

export type ItemSuggestionsQueryVariables = Exact<{
  input: ItemSuggestionsInput;
}>;


export type ItemSuggestionsQuery = { __typename?: 'Query', itemsSuggestions: { __typename?: 'ItemSuggestionsResponse', suggestions: Array<{ __typename?: 'ItemSuggestion', collectionSlug?: string | null, id: number, image?: string | null, title: string, header?: string | null }> } };

export type RedirectsQueryVariables = Exact<{ [key: string]: never; }>;


export type RedirectsQuery = { __typename?: 'Query', redirects: Array<{ __typename?: 'RedirectResponse', fromUrl: string, id: number, toUrl: string }> };
