import type { LinkResponse } from "@/data/__generated__/types/generated";

import type React from "react";

import NextLink from "next/link";

import { getImageSource } from "@/helpers";
import { cn } from "@/libs/utils";

type DesktopPolygonTilesProps = {
	homeLinks?: LinkResponse[] | null;
	homeLinksLoading?: boolean;
};

type TilesRowProps = {
	links: LinkResponse[];
	startOffset: number;
	topClass: string;
};

type TileProps = {
	indexOffset: number;
	link: LinkResponse;
};

const PlaceholderTile: React.FC<{ indexOffset: number }> = ({ indexOffset }) => {
	return (
		<div className={`tile-polygon-${indexOffset} bg-mediumGray w-[384px] overflow-hidden relative`}>
			<div className="w-full h-full animate-pulse bg-mediumGray" />
			<div
				className={`left-6 bg-primaryBlack/50 py-3 px-6 rounded-xl backdrop-blur-sm w-max absolute ${
					indexOffset > 2 ? "bottom-6" : "top-6"
				}`}
			>
				<span className="text-white font-medium">Загрузка...</span>
			</div>
		</div>
	);
};

const Tile: React.FC<TileProps> = ({ link, indexOffset }) => {
	const imageSource = getImageSource({
		height: 1280,
		width: 1920,
		type: "links",
		thumbnail: link.image,
	});

	return (
		<div className={`tile-polygon-${indexOffset} bg-mediumGray w-[384px] overflow-hidden cursor-pointer relative`}>
			<NextLink href={link.url} className="group" prefetch={true}>
				<div className="w-full h-full group-hover:scale-[1.1] group-hover:blur-sm transform transition duration-500 will-change-transform">
					<img src={imageSource} alt={link.url || "Tile image"} className="w-full h-full object-cover" />
				</div>
				<div
					className={cn(
						"left-6 bg-primaryBlack/50 py-3 px-6 rounded-xl backdrop-blur-sm w-max absolute group-hover:bg-primaryBlack/100 transition duration-500",
						indexOffset > 2 ? "bottom-6" : "top-6"
					)}
				>
					<span className="text-white font-medium">{link.title}</span>
				</div>
			</NextLink>
		</div>
	);
};

const TilesRow: React.FC<{ isLoading?: boolean } & TilesRowProps> = ({
	links,
	startOffset,
	topClass,
	isLoading = false,
}) => {
	return (
		<div className={`flex justify-between absolute left-0 w-full ${topClass}`}>
			{isLoading
				? Array.from({ length: startOffset < 6 ? 3 : 2 }, (_, index) => (
						<PlaceholderTile key={`placeholder-${startOffset + index}`} indexOffset={startOffset + index} />
					))
				: links.map((link, index) => <Tile key={link.id} link={link} indexOffset={startOffset + index} />)}
		</div>
	);
};

const DesktopPolygonTiles: React.FC<DesktopPolygonTilesProps> = ({ homeLinks = [], homeLinksLoading }) => {
	const isLoading = homeLinksLoading;
	if (!homeLinks) return null;
	return (
		<div className="mt-[120px] sm:hidden">
			<h1 className="text-5xl font-medium">Популярная керамическая плитка</h1>
			<div className="w-full mt-12 relative min-h-[825px]">
				<TilesRow
					links={isLoading ? [] : homeLinks.slice(0, 3)}
					startOffset={0}
					topClass="top-0"
					isLoading={isLoading}
				/>
				<TilesRow
					links={isLoading ? [] : homeLinks.slice(3, 6)}
					startOffset={3}
					topClass="top-[273px]"
					isLoading={isLoading}
				/>
				<TilesRow
					links={isLoading ? [] : homeLinks.slice(6, 8)}
					startOffset={6}
					topClass="top-[597px]"
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};

export default DesktopPolygonTiles;
